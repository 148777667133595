import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import { Flex, Card, Loader, Alert, SelectField, Divider } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';

import { I18n } from '@aws-amplify/core';
import { strings } from './strings';

import {
  Unsubscribe
} from './ui-components';

I18n.putVocabularies(strings);

function getInitialLanguage() {
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const lang = queryParams.get('lang');

  // Check if lang is one of 'en', 'ru', or 'uk'; otherwise, use 'en' as the default.
  return ['en', 'ru', 'uk'].includes(lang) ? lang : 'en';
}

const overrideProps = {
  Field0: {
    label: I18n.get('promoEmailsAnimalIdCheckbox'),
  },
  SectionalElement0: {
    children: I18n.get('title'),
  },
  SectionalElement1: {
    children: I18n.get('subTitle'),
  },
  SubmitButton: {
    children: I18n.get('submitButton'),
  },
};




function App() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  //const [lang, setLang] = useState('en');
  const [selectedLanguage, setSelectedLanguage] = useState(getInitialLanguage());

  useEffect(() => {
    // Set the language using I18n.setLanguage
    I18n.setLanguage(selectedLanguage);
    updateOverrides();
  }, [selectedLanguage]);

  const [dynamicOverrides, setDynamicOverrides] = useState({
    Field0: {
      label: I18n.get('promoEmailsAnimalIdCheckbox'),
    },
    Field1: {
      label: I18n.get('promoEmailsPartnersCheckbox'),
    },
    SectionalElement0: {
      children: I18n.get('title'),
    },
    SectionalElement1: {
      children: I18n.get('subTitle'),
    },
    SubmitButton: {
      children: I18n.get('submitButton'),
    },
  });

  function handleChangeLanguage(event) {
    const newLang = event.target.value;
    // You can update the URL here with the new language parameter if needed.
    // For now, just set the selected language in state.
    setSelectedLanguage(newLang);
    I18n.setLanguage(newLang);
    updateOverrides();
  }


  // Function to update specific overrides
  const updateOverrides = () => {
    setDynamicOverrides({
      ...dynamicOverrides,
      Field0: {
        label: I18n.get('promoEmailsAnimalIdCheckbox'),
      },
      Field1: {
        label: I18n.get('promoEmailsPartnersCheckbox'),
      },
      SectionalElement0: {
        children: I18n.get('title'),
      },
      SectionalElement1: {
        children: I18n.get('subTitle'),
      },
      SubmitButton: {
        children: I18n.get('submitButton'),
      },
    });
  };


  const handleSubmit = async (event) => {
    console.log(event);
    //event.preventDefault();

    // Get the query string portion of the URL
    const queryString = window.location.search;

    // Parse the query string using the URLSearchParams API
    const queryParams = new URLSearchParams(queryString);

    // Access individual query parameters by their names
    const parameterValue = queryParams.get('parameterName');

    // Example: If the URL is "http://example.com/?name=John&age=30"
    const uid = queryParams.get('uid');   // "30"
    const email = queryParams.get('email'); // "John"
    const token = queryParams.get('token');   // "30"
    event['uid'] = uid;
    event['email'] = email;
    event['token'] = token;

    console.log(event);

    try {
      setLoading(true);
      // Make API request using Amplify API module
      const apiResponse = await API.post('unsubscribe', '/unsubscribe', {
        body: event,
      });

      console.log('API Response:', apiResponse);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      setSuccess(false);
      setError(true);
    }
  };

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <Card maxWidth="500px">
        <Flex direction="column">

          <SelectField
            size="small"
            onChange={handleChangeLanguage}
            padding="0 0 40px 0"
            value={selectedLanguage} // Set the selected value based on the lang state
          >
            <option value="en">EN</option>
            <option value="uk">UK</option>
            <option value="ru">RU</option>
          </SelectField>

        </Flex>
        {success && <Alert variation="success">{I18n.get('successMessage')}</Alert>}
        {error && <Alert variation="error">{I18n.get('errorMessage')}</Alert>}
        {
          !success && !error &&
          <Unsubscribe
            overrides={dynamicOverrides}
            onSubmit={fields => {
              handleSubmit(fields)
            }}
          />
        }
        {loading && <Loader variation="linear" />}

      </Card >
    </Flex >
  );
}

export default App;
