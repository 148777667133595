export const strings = {
    en: {
        promoEmailsAnimalIdCheckbox: 'Special offers from Animal ID',
        promoEmailsPartnersCheckbox: 'Special offers and promo emails from our partners',
        title: 'Select emails you do not want to receive',
        subTitle: 'This will not affect important emails (e.g. your pet\'s QR tag scanned notification, password restore, QR pet tag order status)',
        submitButton: 'Confirm',
        successMessage: 'Successfully, you will no longer receive such emails',
        errorMessage: 'Oops, an error has occurred. Our team is already investigating this problem, you can contact the support team by writing to support@animal-id.net',
    },
    uk: {
        promoEmailsAnimalIdCheckbox: 'Акції та промо пропозиції від Animal ID',
        promoEmailsPartnersCheckbox: 'Акції та промо пропозиції від партнерів',
        title: 'Вкажіть, які листи Ви більше не хочете отримувати',
        subTitle: 'Це не вплине на важливі електронні листи (наприклад, сповіщення про сканування QR-жетону вашої тварини, відновлення пароля, статус замовлення QR-жетона)',
        submitButton: 'Підтвердити',
        successMessage: 'Налаштування збережено. Ви більше не отримуватимете такі електронні листи',
        errorMessage: 'На жаль, сталася помилка. Наша команда вже досліджує цю проблему, ви можете зв’язатися зі службою підтримки, написавши на адресу support@animal-id.net',
    },
    ru: {
        promoEmailsAnimalIdCheckbox: 'Акции и промо предложения от Animal ID',
        promoEmailsPartnersCheckbox: 'Акции и промо предложения от партнеров',
        title: 'Выберите письма, которые больше не хотите получать',
        subTitle: 'Это не повлияет на важные электронные письма (например, уведомление о сканировании QR-тега вашего питомца, восстановление пароля, статус заказа QR-тега для домашнего животного)',
        submitButton: 'Подтвердить',
        successMessage: 'Настройки сохранены. Вы больше не будете получать подобных писем',
        errorMessage: 'Произошла ошибка. Наша команда уже изучает эту проблему, Вы можете связаться со службой поддержки, написав на адрес support@animal-id.net',
    },
};